import React, { useRef, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Flex, Modal, Tabs, useToast } from '@beachfront/ui'
import { useSessionStorage } from '@beachfront/ui/hooks'
import { ReloadOutlined } from '@beachfront/ui/icons'
import PropTypes from 'prop-types'

import { api } from '../../client-api'
import { getErrorMessage, plural } from '../../utils'
import { IS_DEALS_ENGINE } from '../../constants'
import { DEAL_STATUS, DATE_RANGES, ACCOUNT_TYPE } from '../../enums'
import { localFilterKey } from '../../components/filter'
import {
  useContentSize,
  useFilters,
  useColumnConfig,
  useDateUrlSync,
  useUserData,
  useInfiniteTable,
} from '../../hooks'
import {
  PageHeader,
  PageSearch,
  ColumnConfig,
  InfiniteTable,
  NetworkError,
} from '../../components'

import { dealColumns } from './@constants'
import { DealFilter } from './@components'

const DealList = ({ admin }) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [user] = useUserData()
  const [dealStatus, setDealStatus] = useSessionStorage(user.accountId, 'all')
  const { contentWidth, contentHeight } = useContentSize()
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const filterKey = admin ? localFilterKey.DEAL_ADMIN : localFilterKey.DEAL
  const filterRef = useRef()
  const [filters, setFilters] = useFilters(filterKey, {
    initialState: [
      { dataIndex: 'date', operator: 'eq', value: DATE_RANGES['Today'] },
      { dataIndex: 'dealStatus', operator: 'eq', value: dealStatus },
    ],
    schema: {
      date: 'date',
      createTime: 'date',
      lastUpdate: 'date',
    },
  })

  useDateUrlSync({
    filters: filters.populated,
    onChange: setFilters,
  })

  const otherFilters = filters.populated.filter(
    (f) => f.dataIndex !== 'date' && f.value !== 'all'
  )

  const params = getRequestParams(filters.populated, searchQuery)

  if (admin) {
    params.admin = true
  }

  const table = useInfiniteTable({
    queryKey: ['self-deals'],
    queryFn: api.deal.list,
    filters: otherFilters,
    fetchTotalSeparately: true,
    staleTime: 0,
    params,
  })

  const scroll = {
    x: contentWidth - 300,
    y: contentHeight - 260 - 53,
  }

  const rowSelection = !admin
    ? {
        selectedRowKeys: selectedRowKeys,
        onChange: setSelectedRowKeys,
        getCheckboxProps: (rec) => ({
          disabled: rec.bulkUnpublishDisabled,
        }),
        columnWidth: 40,
        preserveSelectedRowKeys: true,
      }
    : null

  const columns = useMemo(() => {
    let cols = [...dealColumns]
    if (
      user.accountType.key === ACCOUNT_TYPE.DSP.key ||
      user.accountType.key === ACCOUNT_TYPE.AGENCY.key ||
      user.accountType.key === ACCOUNT_TYPE.RESELLER.key
    ) {
      cols = cols.filter((c) => c.dataIndex !== 'wins')
    }
    if (admin) {
      cols.splice(1, 0, {
        dataIndex: 'company',
        title: 'Company',
        sorter: true,
        width: 180,
      })
      cols = cols.filter((c) => c.dataIndex !== 'spend')
    }
    if (dealStatus !== 'all') {
      cols = cols.filter((c) => c.dataIndex !== 'dealStatus')
    }
    return cols
  }, [user, admin, dealStatus])

  const [columnConfig, setColumnConfig] = useColumnConfig(
    columns,
    'deal-list-column-config'
  )

  const clearSelectedRowKeys = () => {
    setSelectedRowKeys([])
  }

  const onBulkUnpublish = () => {
    Modal.confirm({
      title: 'Unpublish Deals',
      content: plural`Are you sure you want to unpublish ${selectedRowKeys.length} deal[|s]?`,
      maskClosable: true,
      onOk() {
        return api.deal.bulk.unpublish({ data: selectedRowKeys }).then(
          (res) => {
            if (res.data?.success) {
              setSelectedRowKeys([])
              table.refetch()
              toast.success({ title: 'Deals unpublished.' })
            } else {
              toast.error({ title: res.data?.msg })
            }
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
          }
        )
      },
    })
  }

  const headerActions = (
    <>
      <PageSearch
        value={searchQuery}
        onChange={setSearchQuery}
        placeholder='Search Deals'
      />
      <ColumnConfig
        columns={columns}
        value={columnConfig}
        onChange={setColumnConfig}
      />
      <Button onClick={() => table.refetch()} icon={<ReloadOutlined />} />
      {!admin ? (
        <Button type='primary' onClick={() => navigate('/deals/self/create')}>
          Create Deal
        </Button>
      ) : null}
    </>
  )

  const toolbar = () => (
    <InfiniteTable.Toolbar
      py={2}
      height={selectedRowKeys.length ? 48 : undefined}
    >
      <Flex justifyContent='space-between'>
        <DealFilter
          filterRef={filterRef}
          filters={filters.all.filter((el) => el.value !== 'all')}
          setFilters={setFilters}
          hideStatus={dealStatus !== 'all'}
        />
      </Flex>
      <InfiniteTable.BatchActions
        count={selectedRowKeys.length}
        onCancel={clearSelectedRowKeys}
      >
        <InfiniteTable.ActionButton onClick={onBulkUnpublish}>
          Unpublish
        </InfiniteTable.ActionButton>
      </InfiniteTable.BatchActions>
    </InfiniteTable.Toolbar>
  )

  return (
    <>
      <PageHeader title='Deals' actions={headerActions} />
      <Box mt={2}>
        {!table.isFetching && table.error ? (
          <NetworkError
            description={getErrorMessage(table.error)}
            onAction={table.refetch}
          />
        ) : (
          <>
            <Tabs
              activeKey={dealStatus}
              onChange={(val) => {
                setDealStatus(val)

                setFilters((prevFilters) => {
                  const mutated = prevFilters.filter(
                    (pf) => pf.dataIndex !== 'dealStatus'
                  )

                  if (val === 'all') {
                    return mutated
                  }

                  return [
                    ...mutated,
                    {
                      dataIndex: 'dealStatus',
                      operator: 'eq',
                      value: val,
                    },
                  ]
                })
              }}
            >
              <Tabs.TabPane key='all' tab='All' />
              {DEAL_STATUS.values().map(({ key, name }) => (
                <Tabs.TabPane key={key} tab={name} />
              ))}
            </Tabs>
            <InfiniteTable
              rowKey='id'
              emptyText='No Deals Found'
              columns={columns}
              toolbar={toolbar}
              scroll={scroll}
              rowSelection={rowSelection}
              filters={filters.populated.filter((el) => el.value !== 'all')}
              onFilter={(key) => filterRef.current?.addFilter(key)}
              columnConfig={columnConfig}
              onColumnConfigChange={setColumnConfig}
              {...table.props}
            />
          </>
        )}
      </Box>
    </>
  )
}

const getRequestParams = (filters, searchQuery) => {
  const dateRange = filters.find((f) => f.dataIndex === 'date')?.value

  return {
    range: 'custom',
    startDate: dateRange?.[0].format('YYYY-MM-DD'),
    endDate: dateRange?.[1].format('YYYY-MM-DD'),
    default: IS_DEALS_ENGINE,
    q: searchQuery,
  }
}

DealList.propTypes = {
  admin: PropTypes.bool,
}

export default DealList

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Grid, Box, Card, Button, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../../client-api'
import { getErrorMessage } from '../../../../utils'
import { PageHeader, DirtyPrompt } from '../../../../components'
import { validateSegmentForm } from '../@utils'

import { SegmentFieldForm, SegmentUploadForm } from '.'

const CreateSegment = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()

  const onSubmit = (values, form) => {
    const payload = {
      name: values.name,
      price: values.price,
      dataType: values.dataType,
      remoteId: values.remoteId,
      status: values.status,
      fileUrl: values.uploadState.url,
    }

    return api.segment.create(payload).then(
      (res) => {
        if (res.data?.success) {
          toast.success({ title: 'Segment created.' })
          queryClient.invalidateQueries({ queryKey: ['uploaded-segments'] })
          form.initialize(values)
          setTimeout(() => navigate('/segment/upload'))
        } else {
          toast.error({
            title:
              res.data?.msg ||
              res.data.errorDetails ||
              'Unable to create this segment. Please try again.',
          })
        }
      },
      (error) => {
        toast.error({ title: getErrorMessage(error) })
      }
    )
  }

  return (
    <Form validate={validateSegmentForm} onSubmit={onSubmit}>
      {({ handleSubmit, submitting, dirty }) => (
        <form onSubmit={handleSubmit}>
          <PageHeader
            title='Upload Segment'
            actions={
              <>
                <Link to='/segment/upload'>
                  <Button>Cancel</Button>
                </Link>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={submitting}
                  disabled={!dirty}
                >
                  Create
                </Button>
              </>
            }
          />
          <DirtyPrompt dirty={dirty} />
          <Box mt={3} height={['auto', 'auto', 'calc(100vh - 130px)']}>
            <Grid gap={3} columns={[1, 1, '350px 1fr']} height='100%'>
              <Card>
                <SegmentFieldForm isCreate={true} />
              </Card>
              <Card>
                <SegmentUploadForm isCreate={true} />
              </Card>
            </Grid>
          </Box>
        </form>
      )}
    </Form>
  )
}

export default CreateSegment

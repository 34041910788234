import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Box, Button, Tabs, Modal, useToast } from '@beachfront/ui'
import { Form } from '@beachfront/ui/forms'

import { api } from '../../../client-api'
import { TIMEZONE } from '../../../enums'
import { useFetch } from '../../../hooks'
import {
  isNotEmptyArray,
  isNotEmptyObject,
  isEmptyString,
  isString,
  isValidMongoId,
  isNumber,
  getErrorMessage,
} from '../../../utils'
import { PageHeader, NetworkError, CommonSpinner } from '../../../components'
import FourOhFour from '../../four-oh-four'
import {
  updateMediaTabs,
  validateForm,
  getKeys,
  getSegmentKeys,
  getExcludeInitialValue,
  getOrAndInitialValue,
} from '..'

import { TargetingForm, SettingForm, MediaPlanHistory } from '.'

const MediaPlanDetail = ({ admin }) => {
  const { type, id } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const queryClient = useQueryClient()
  const [isCloning, setIsCloning] = useState(false)
  const [isMediaPlanUpdate, setIsMediaPlanUpdate] = useState(false)
  const [timezone, setTimezone] = useState(TIMEZONE.EST.key)
  const [availsDsp, setAvailsDsp] = useState('')

  const baseLocation = admin ? '/admin/mediaplan' : '/mediaplan'
  const backUrl = admin ? '/admin/mediaplan' : '/mediaplan'

  const onTabChange = (key) => {
    navigate(`${baseLocation}/${id}/${key}`)
  }

  const onDuplicate = () => {
    const postData = {
      data: [id],
    }
    setIsCloning(true)
    return api.mediaPlan
      .duplicate(postData)
      .then(
        (res) => {
          if (res.data?.success) {
            toast.success({ title: 'Media plan duplicated.' })
            queryClient.invalidateQueries({ queryKey: ['media-plans'] })
            navigate(`/mediaplan/${res.data.value}/targeting`)
          } else {
            toast.error({
              title: res.data?.msg || 'Please try again.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
      .finally(() => setIsCloning(false))
  }

  const onDelete = () => {
    Modal.confirm({
      title: 'Delete Media Plan',
      content: `Are you sure you want to delete media plan "${response.data?.title}"?`,
      okText: 'Delete',
      okButtonProps: { danger: true },
      maskClosable: true,
      onOk() {
        return api.mediaPlan.delete(id).then(
          () => {
            toast.success({ title: 'Media plan deleted.' })
            queryClient.invalidateQueries({ queryKey: ['media-plans'] })
            navigate(backUrl)
          },
          (error) => {
            toast.error({ title: getErrorMessage(error) })
            throw error
          }
        )
      },
    })
  }

  const params = {}

  if (admin) {
    params.admin = true
  }

  const response = useFetch({
    request: api.mediaPlan.getById,
    payload: { id, params },
  })

  const initialValues = useMemo(() => {
    let getData = {}
    if (response.data) {
      getData = {
        ...response.data,
        audienceBooleanLogic: response.data.audienceBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        contentBooleanLogic: response.data.contentBooleanLogic ?? {
          enabled: false,
          client: [],
          value: '',
        },
        mediaType: response.data.mediaType ? [response.data.mediaType] : [],
        status: response.data.status ? 'ENABLE' : 'DISABLE',
        country: response.data.country ? getKeys(response.data.country) : [],
        countryObj: response.data.country ? response.data.country : [],
        genres: response.data.genres ? getKeys(response.data.genres) : [],
        rating: response.data.rating ? getKeys(response.data.rating) : [],
        network: response.data.network ? getKeys(response.data.network) : [],
        channel: response.data.channel ? getKeys(response.data.channel) : [],
        genresObj: response.data.genres ? response.data.genres : [],
        networkObj: response.data.network ? response.data.network : [],
        ratingObj: response.data.rating || [],
        channelObj: response.data.channel ? response.data.channel : [],
        iabCategory: response.data.iabCategory
          ? getKeys(response.data.iabCategory)
          : [],
        iabCategoryObj: response.data.iabCategory
          ? response.data.iabCategory
          : [],
        state: response.data.state ? getKeys(response.data.state) : [],
        stateObj: response.data.state ? response.data.state : [],
        publishers: response.data.publishers
          ? getKeys(response.data.publishers)
          : [],
        buyers: response.data.buyer ? response.data.buyer.key : [],
        publishersObj: response.data.publishers ? response.data.publishers : [],
        dma: response.data.dma ? getKeys(response.data.dma, 'dmaCode') : [],
        dmaObj: response.data.dma ? response.data.dma : [],
        app: response.data.app ? getKeys(response.data.app) : [],
        appObj: response.data.app ? response.data.app : [],
        excludeCountry: getExcludeInitialValue(response.data.excludeCountry),
        excludeDma: getExcludeInitialValue(response.data.excludeDma),
        excludeState: getExcludeInitialValue(response.data.excludeState),
        excludeiabCat: getExcludeInitialValue(response.data.excludeiabCat),
        excludeNetwork: getExcludeInitialValue(response.data.excludeNetwork),
        excludeChannel: getExcludeInitialValue(response.data.excludeChannel),
        excludeGenres: getExcludeInitialValue(response.data.excludeGenres),
        excludeRating: getExcludeInitialValue(response.data.excludeRating),
        excludeApp: getExcludeInitialValue(response.data.excludeApp),
        includeSegmentAnd: getOrAndInitialValue(
          response.data.includeSegmentAnd
        ),
        excludeSegmentAnd: getOrAndInitialValue(true),
        excludeUserId: getExcludeInitialValue(response.data.excludeUserId),
      }
    }
    return getData
  }, [response.data])

  if (!type) {
    return (
      <Navigate
        to={`${baseLocation}/${id}/${updateMediaTabs[0].key}`}
        replace
      />
    )
  }

  const onSubmit = (values, form) => {
    if (
      values.title &&
      (!values.audienceBooleanLogic.enabled ||
        isNotEmptyArray(values?.audienceBooleanLogic.client)) &&
      (!values.contentBooleanLogic.enabled ||
        isNotEmptyArray(values?.contentBooleanLogic.client))
    ) {
      let targetData = {
        platform: values.platform.includes('ALL') ? ['ALL'] : values.platform,
        country: values.country || [],
        marketplaceIds: values.marketplaceIds || [],
        dma: [],
        state: [],
        includeLivestream: values.includeLivestream,
        excludeCountry: values.excludeCountry === 'EXCLUDE',
        excludeDma: values.excludeDma === 'EXCLUDE',
        excludeState: values.excludeState === 'EXCLUDE',
        excludeiabCat: values.excludeiabCat === 'EXCLUDE',
        excludeGenres: values.excludeGenres === 'EXCLUDE',
        excludeChannel: values.excludeChannel === 'EXCLUDE',
        excludeNetwork: values.excludeNetwork === 'EXCLUDE',
        excludeUserId: values.excludeUserId === 'EXCLUDE',
        excludeRating: values.excludeRating === 'EXCLUDE',
        excludeApp: values.excludeApp === 'EXCLUDE',
      }

      if (
        isNotEmptyArray(values.country) &&
        (values.country.includes('US') || values.country.includes('CA'))
      ) {
        if (isNotEmptyArray(values.dma)) {
          targetData['dma'] = values.dma
        }
        if (isNotEmptyArray(values.state)) {
          targetData['state'] = values.state
        }
      }
      if (isNotEmptyArray(values.genres)) {
        targetData['masterListGenres'] = values.genres.filter(
          (item) => isString(item) && isValidMongoId(item)
        )
        targetData['genres'] = values.genres.filter((item) => isNumber(item))
      }

      if (isNotEmptyArray(values.channel)) {
        targetData['channel'] = values.channel
      }
      if (isNotEmptyArray(values.network)) {
        targetData['network'] = values.network
      }
      if (isNotEmptyArray(values.iabCategory)) {
        targetData['iabCategory'] = values.iabCategory
      }
      if (isNotEmptyArray(values.app)) {
        targetData['app'] = values.app
      }
      if (isNotEmptyArray(values.publishers)) {
        targetData['userid'] = values.publishers
      }
      if (isNotEmptyArray(values.rating)) {
        targetData['rating'] = values.rating
      }

      const postData = {
        audienceBooleanLogic: values.audienceBooleanLogic,
        contentBooleanLogic: values.contentBooleanLogic,
        title: values.title,
        status: values.status === 'ENABLE',
        mediaType: isNotEmptyArray(values.mediaType)
          ? values.mediaType[0]
          : null,
        targeting: targetData,
        includeSegments: isNotEmptyArray(values.includeSegments)
          ? getSegmentKeys(values.includeSegments)
          : [],
        excludeSegments: isNotEmptyArray(values.excludeSegments)
          ? getSegmentKeys(values.excludeSegments)
          : [],
        domainMedia:
          values.domainMedia && isNotEmptyArray(values.domainMedia.ids)
            ? {
                ...values.domainMedia,
                ids: getKeys(values.domainMedia.ids, 'id'),
              }
            : null,
        bundleMedia:
          values.bundleMedia && isNotEmptyArray(values.bundleMedia.ids)
            ? {
                ...values.bundleMedia,
                ids: getKeys(values.bundleMedia.ids, 'id'),
              }
            : null,
        zipMedia: null,
        dmaMedia: null,
        includeSegmentAnd: values.includeSegmentAnd === 'AND',
      }

      if (values.country?.includes('US') || values.country?.includes('CA')) {
        if (values.zipMedia?.ids?.length) {
          postData.zipMedia = {
            ...values.zipMedia,
            ids: getKeys(values.zipMedia.ids, 'id'),
          }
        }
        if (values.dmaMedia?.ids?.length) {
          postData.dmaMedia = {
            ...values.dmaMedia,
            ids: getKeys(values.dmaMedia.ids, 'id'),
          }
        }
      }

      if (Object.hasOwn(values, 'buyers')) {
        postData.buyerId = values.buyers
      }

      return api.mediaPlan.update(id, postData).then(
        (res) => {
          if (res.data?.success) {
            toast.success({ title: 'Media plan updated.' })
            queryClient.invalidateQueries({ queryKey: ['media-plans'] })
            setIsMediaPlanUpdate(false)
            form.initialize(values)
          } else {
            toast.error({
              title:
                res.data?.msg ||
                res.data.errorDetails ||
                'Unable to update this media plan. Please try again.',
            })
          }
        },
        (error) => {
          toast.error({ title: getErrorMessage(error) })
        }
      )
    } else {
      let field = ''
      let tab = ''
      if (
        values.title &&
        values.audienceBooleanLogic.enabled &&
        !isNotEmptyArray(values?.audienceBooleanLogic.client)
      ) {
        field = 'Advance Boolean Logic'
        tab = 'targeting'
      } else if (
        values.title &&
        values.contentBooleanLogic.enabled &&
        !isNotEmptyArray(values?.contentBooleanLogic.client)
      ) {
        field = 'Content Boolean Logic'
        tab = 'targeting'
      }
      toast.error({
        title: `Please fill ${isEmptyString(field) ? 'title' : field} in ${
          isEmptyString(tab) ? 'setting' : tab
        } tab`,
      })
    }
  }

  if (response.error) {
    return (
      <NetworkError
        description={response.error || 'Media Plan Not Found'}
        buttonLabel='Back to Media Plans'
        onAction={() => navigate(backUrl)}
      />
    )
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={(values) => validateForm({ values, tab: type })}
    >
      {({ initialValues, handleSubmit, submitting, values, form }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <PageHeader
            title={`Media Plan - ${
              type.charAt(0).toUpperCase() + type.slice(1)
            }`}
            actions={
              <>
                <Button
                  disabled={admin || response?.data?.mediaPlanDisabled}
                  onClick={onDelete}
                  danger
                >
                  Delete
                </Button>
                {!admin ? (
                  <Button
                    onClick={onDuplicate}
                    loading={isCloning}
                    disabled={response?.data?.mediaPlanDisabled}
                  >
                    Duplicate
                  </Button>
                ) : null}
                <Link to={backUrl}>
                  <Button>Cancel</Button>
                </Link>
                {type !== 'history' && !admin ? (
                  <Button
                    type='primary'
                    htmlType='submit'
                    disabled={!isMediaPlanUpdate}
                    loading={submitting}
                  >
                    Update
                  </Button>
                ) : null}
              </>
            }
          />
          <Box mt={3}>
            <Tabs activeKey={type} onChange={onTabChange} type='card'>
              {updateMediaTabs.map((tabData) => {
                const FormComponent = getFormComponent(tabData.key)
                const params = { id: values.id }
                if (admin) {
                  params.admin = true
                }
                return (
                  <Tabs.TabPane tab={tabData.title} key={tabData.key}>
                    {response.loading ? (
                      <CommonSpinner />
                    ) : isNotEmptyObject(values) ? (
                      <FormComponent
                        availsDsp={availsDsp}
                        id={values.id}
                        type={type}
                        form={form}
                        initialValues={initialValues}
                        values={values}
                        timezone={timezone}
                        params={params}
                        disableForm={response?.data?.mediaPlanDisabled}
                        superAdmin={admin}
                        update={true}
                        publishers={true}
                        setIsMediaPlanUpdate={setIsMediaPlanUpdate}
                        onAvailsDspChange={setAvailsDsp}
                        onTimezoneChange={setTimezone}
                      />
                    ) : null}
                  </Tabs.TabPane>
                )
              })}
            </Tabs>
          </Box>
        </form>
      )}
    </Form>
  )
}

const getFormComponent = (type) => {
  switch (type) {
    case 'settings':
      return SettingForm
    case 'targeting':
      return TargetingForm
    case 'history':
      return MediaPlanHistory
    default:
      return FourOhFour
  }
}

MediaPlanDetail.defaultProps = {
  admin: false,
}

MediaPlanDetail.propTypes = {
  admin: PropTypes.bool,
}

export default MediaPlanDetail
